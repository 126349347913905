import React from 'react'
import { useState } from 'react'
import Enrollcard from './Enrollcard'

const Coursecard = ({ data }) => {

    const [modal, setmodal] = useState(false)
    return (
        <div  className="box mybox">

            <h3 style={{fontSize:30}}>{data.programname}</h3>
            <p>{data.description}</p>

            {data.skillsicon.map((val)=>(
                <img src={val} style={{margin:10,width:50,height:50,backgroundColor:'#49915e',padding:5,borderRadius:50}}/>
            ))}
            {/* <p>{dessss}{desc.length> 200?'...':null}</p>
           <a href={url} className="btn">Read more</a> */}
            <h3 style={{border:0,margin:'10px 0px'}}>Languages & Tools you will learn </h3>
            {data.skills.map((val, i) => (
                <p style={{ color:'black',textAlign: 'center' }}>{i + 1}. {val}</p>
            ))}
            

            <h3 style={{fontSize:16,border:0,margin:'10px 0px'}}>Program Duration: {data.programduration}</h3>
            <h3 style={{fontSize:16,border:0,margin:'10px 0px'}}>Learning Format: Online Class via Zoom </h3>
           
            <h3 style={{fontSize:16,border:0,margin:'10px 0px'}}>Program Fees: Call Now - +917509461405, +917898620456 </h3>
            <h3 style={{fontSize:16,border:0,margin:'10px 0px'}}>Email: support@gwalax.in</h3>

           
            <a href="tel:+917898620456" className='btn' style={{margin:'10px 0px',width:'90%'}}>Get Enquiry</a><br/>
            <a onClick={()=>setmodal(true)} className='btn' style={{margin:'10px 0px',width:'90%',backgroundColor:'#4372b0',color:'white'}}>Enroll Now</a>

            <Enrollcard modal={modal} setmodal={setmodal}/>

           


        </div>
    )
}

export default Coursecard