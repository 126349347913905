

import * as React from "react";
import Header from "./Components/Header";
import MyComponent from "./Components/MyComponent";


export default function LandingpageforGwalior() {
  return (
    <div className="md:flex flex-col items-center pt-2.5">
      <Header />
      {/* <MainLayout/> */}
      <MyComponent city={'Gwalior'}/>
     
    </div>
  );
}
