import React from 'react'
import CountUp, { useCountUp } from 'react-countup';

const Archmentcard = ({data}) => {
  return (
    <div className="box flex flex-col justify-center items-center">
    <img style={{width:50,height:50}} src={data.img} alt=""/>
    <h3 className='font-bold'>{data.name}</h3>
    <div className="amount"> <CountUp style={{fontWeight:'bold',fontSize:'5rem'}} duration={5}  end={data.num} />+</div>
    
    
</div>
  )
}

export default Archmentcard