import React from 'react'
import Loveusecard from './Loveusecard'

const Loveusers = () => {


    return (

        <section style={{margin:'0px 0px'}} className="project" id="project">

        <h1 className="heading font-bold">Love from Clients</h1>
            <div style={{ }}>

                <Loveusecard />



            </div>



        </section>

    )
}

export default Loveusers