import React from 'react'

const Footer = () => {
  return (
    <div className="footer">
        
         

    <div className="box-container">

        

        <div className="box">
            <h3>About us</h3>
            <p style={{letterSpacing:0.8}}>Gwalax is a Gwalior MP based Best Web Designing & Web Development Company In Gwalior. The company was launched in 2019. web professionals to turn their IT dreams in reality and to serve individuals or organizations for their web related needs.
                we are working on Website Development, App Development, Digital Marketing
            </p>
        </div>

        <div className="box">
            <h3>Quick Links</h3>
            <a href="/">Home</a>
            <a href="/about">About us</a>
            <a href="/services">Services</a>
            <a href="/technologies">Technologies</a>
            <a href="#">Team</a>
            <a href="/career">Career</a>
            <a href="/contact">Contact us</a> 
            <a href="/projects">Projects</a> 
        </div>

        <div className="box">
            <h3>Category</h3>
            <a href="/web-development">Web Development</a>
            <a href="/app-development">App Development</a>
            <a href="/software-development">Software Development</a>
            <a href="/digital-marketing">Digital Marketing</a>
            <a href="/seo-optimization">SEO Optimization</a>
            <a href="/ui-design">UI/UX Design</a>
        </div>

        <div className="box">
            <h3>Follow Us</h3>
            <a target='_blank' href="https://www.facebook.com/Gwalax.in/">Facebook</a>
            <a target='_blank' href="https://t.me/s/gwalax">Telegram</a>
            <a target='_blank' href="/">Instagram</a>
            <a target='_blank' href="https://www.linkedin.com/company/gwalax">Linkedin</a>
        </div>

    </div>
    <h1 className="credit"> &copy; 2025 All rights reserved to <a href="/"> Gwalax </a>  </h1>


   

</div>
  )
}

export default Footer