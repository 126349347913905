import React from "react";

const progress = [
  {
    textValue: "4.9",
    subText: "Ratings",
    alt: 'Ratings'
  },
  {
    textValue: "7+",
    subText: "Years of Experience",
    alt: 'Years of Experience'
  },
  {
    textValue: "100+",
    subText: "Happy Clients",
    alt: 'Happy Clients'
  }
];

function ProgressCards() {
  return (
    <div className="flex flex-wrap gap-5 justify-center items-center px-2.5 py-16 mt-20 max-w-full">
      {progress.map((item, index) => (
        <div key={index} className="flex flex-col flex-1 shrink px-12 py-5 whitespace-nowrap rounded-md border border-solid basis-0 border-[black] min-w-[240px] max-md:px-5">
          <div className="flex gap-1 text-7xl font-bold text-sky-600 leading-[70px]">
            <div className="grow text-center max-md:text-4xl">{item.textValue}</div>
            <div className={index === 1 ? "self-start max-md:text-4xl mt-3.5" : "self-start max-md:text-4xl"}>{index === 1 ? '' : '*'}</div>
          </div>
          <div className="self-center mt-6 text-lg font-medium leading-10 text-black">
            {item.subText}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProgressCards;