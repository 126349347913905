import React from "react";

const logos = [
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/f476c559301e8bb03c8e52ac39c7997e3e914332617ccd7f573a42965cc3b039?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c", aspectRatio: "aspect-[3.25]", width: "w-[70px]", alt: 'logo-1' },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/608f4d40c001d8f7584cec023ce7949984b6b7125f1dd7febdabb8b9e0ef4b62?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c", aspectRatio: "aspect-[3.25]", width: "w-[70px]", alt: 'logo-2' },
  { src: "https://cdn.builder.io/api/v1/image/assets%2F39ad5d3b843547288fd1e2c2a611a66c%2F65f9b926f1d742e0a945d11e95b96b49", aspectRatio: "aspect-[1.63]", width: "w-[70px]", alt: 'logo-3' },
  { src: "https://cdn.builder.io/api/v1/image/assets%2F39ad5d3b843547288fd1e2c2a611a66c%2F9aeb557344f04447bb419f2ee7a1b1de?format=webp", aspectRatio: "aspect-[3.69]", width: "w-[70px]", alt: 'logo-4' },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0b83848e4f1e206e4fec072647b3bd6356ac59bec67f8f092c08ddc685f33ac7?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c", aspectRatio: "aspect-[1.64]", width: "w-[70px]", alt: 'logo-5' },
  { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b2ca357fd74800f6c8cba7042f4a302e2901299ec93631003f3ff9821598c642?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c", aspectRatio: "aspect-[3.25]", width: "w-[70px]", alt: 'logo-6' }
];

function TechnologyLogos({city}) {
  return (
    <div className="flex flex-col items-center p-2.5 mt-3 max-w-full min-h-[241px] w-[1140px]">
      <div className="text-6xl font-bold leading-49 mb-7 mt-6  text-center text-black max-md:max-w-full">
        Innovative Technology for Superior Websites
      </div>
      <div className="mt-5 text-xl leading-6 text-center text-neutral-700 max-md:max-w-full">
        At Gwalax, we use the latest technologies to create websites
        that are not only visually appealing but also functionally robust.
        <br />
        From HTML5 and CSS3 to advanced JavaScript frameworks, our tech stack
        ensures your website stands out in the competitive
        <br />
        {city} market.
      </div>
      <div className="flex flex-wrap gap-5 justify-between items-start py-3.5 pr-2.5 pl-7 mt-5 w-full max-md:pl-5">
        {logos.map((logo, index) => (
          <img
            key={index}
            loading="lazy"
            src={logo.src}
            className={`object-contain shrink-0 ${logo.aspectRatio} ${logo.width}`}
            alt={logo.alt}
          />
        ))}
      </div>
    </div>
  );
}

export default TechnologyLogos;