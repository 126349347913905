import React from 'react'
import Archmentcard from './Archmentcard'

const Archivment = () => {

    const archdata = [
        {name:'Years of Growing',num:7,img:'/img/calendar.png'},

        {name:'Projects Delivered',num:150,img:'img/flowchart.png'},

        {name:'Industries Served',num:10,img:'img/factory.png'},

        {name:'Satisfied Client',num:100,img:'img/target-audience.png'},
    ]
    return (
        <section style={{margin:'30px 0px', minHeight:'65vh'}} className="price" id="price">

            <h1 className="heading font-bold">Achievements</h1>

            <div className="box-container">

                {archdata.map((val,i)=>(<Archmentcard key={i} data={val}/>))}

            </div>
        </section>
    )
}

export default Archivment