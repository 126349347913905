import React from 'react'
import Feuturebox from './Feuturebox'

const Feuture = ({heading}) => {

  return (
    <section style={{margin:'30px 0px'}} className="feature" id="feature">

    {heading?<h1 className="heading font-bold"> awesome features </h1>:null}

    <div className="box-container">

        <Feuturebox img='img/responsive.png' desc={'Gwalax is a leading web development company based out in Gwalior MP, India. Over the past years, we have offered all kinds of custom build websites, web portals, and web applications. We use cutting edge technologies to transform the ideas into reality.'} title={'Web Development'} url={'/web-development'}/>
        
        <Feuturebox img='img/smartphone.png' desc={'We are the top Android App Development Company that works on your app idea and make it a reality! We deliver the best and exceptional app development experience at competitive rates. Our skilled Android developers have an excellent grip on Android App Design and Development. We have delivered a lot of Android Applications to our clients, '} title={'App Development'} url={'/app-development'}/>
        
        <Feuturebox img='img/softwaredev.png' desc={'Gwalax is the renowned name in the world of software development. Over the past years, our growing organization has achieved a lot and successfully delivered several major projects to our clients. '} title={'Software Development'} url={'/software-development'}/>

        <Feuturebox img='img/ux.png' desc={'Simple, and advanced websites that represent your business in its true form to your visitors and prospects. We undertake the design, development, features addition and all other important aspects of website development. All our websites are fast, secure, flexible and compatible on different OS, platforms and screen sizes.'} title={'UI Design'} url={'/ui-design'}/>

        <Feuturebox img='img/digital-campaign.png' desc={'We provide complete digital marketing solutions like SEO, social media marketing, influencer marketing, programmatic advertising, Email marketing, conversion optimization, and online reputation management. Being one of the best marketing companies in Gwalior MP, Bizzeonline is committed to giving innovative and quality digital marketing services to its clients pertinent to their business goals. '} title={'Digital Marketing'} url={'/digital-marketing'}/>

        <Feuturebox img='img/project.png' desc={'The best way to get your website noticed by potential customers. SEO brings the business at amazing heights since it enables us to work across the globe just by investing a bit of investment.'} title={'SEO Optimization'} url={'/seo-optimization'}/>



        

    </div>

</section>
  )
}

export default Feuture