import React from 'react'
import Technolybox from './Technolybox'
import {Rings} from 'react-loader-spinner'

const UseTechnologies = ({heading}) => {
  return (
    <section style={{margin:'30px 0px'}} className="feature" id="feature">

    {heading?<h1 className="heading font-bold"> Technologies we use </h1>:null}

    <div className="box-container">

        <Technolybox img='img/html-5.png' title='HTML'  />

        <Technolybox img='img/css.png' title='CSS' />
        <Technolybox img='img/javascript.png' title='JavaScript' />
        <Technolybox img='img/bootstrap.png' title='BootStrap' />
        <Technolybox img='img/Tailwind.png' title='Tailwind CSS' />
        <Technolybox img='img/react.png' title='React' />
        <Technolybox img='img/Vuejs1.svg' title='Vue' />
        <Technolybox img='img/Angularjs1.svg' title='Angular' />
        <Technolybox img='img/react.png' title='React Native' />
        <Technolybox img='img/typescript.png' title='Typescript' />

        <Technolybox img='img/nodejs.png' title='NodeJS' />
        <Technolybox img='img/mongodb1.svg' title='MongoDB' />
        <Technolybox img='img/mysql.png' title='MySQL' />
        <Technolybox img='img/wordpress.png' title='Wordpress' />
        <Technolybox img='img/figma.png' title='Figma' />
        <Technolybox img='img/photoshop.png' title='Photoshop' />
        <Technolybox img='img/xd.png' title='Adobe XD' />

       



        

        

        

        
        
        



        

    </div>

</section>
  )
}

export default UseTechnologies