import * as React from "react";

export default function FeatureBox({ icon, text }) {
  return (
    <div className="flex flex-col grow shrink justify-center py-px pr-8 pl-5 bg-sky-100 rounded border border-sky-600 border-dashed min-w-[240px] w-[203px] max-md:px-5">
      <div className="flex gap-2.5 items-center w-full">
        {icon && (
          <img
            loading="lazy"
            src={icon}
            alt=""
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[25px]"
          />
        )}
        <div className="self-stretch my-auto text-2xl font-bold leading-6 text-black">
          {text}
        </div>
      </div>
    </div>
  );
}