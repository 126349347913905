import React from 'react'

const Homecom = () => {

    
  return (
    <section className="home" id="home">

    <div className="content">
        <h3 className='font-bold break-words'>grow your business with us</h3>
        <p>We are an award-winning IT company with 1 office in India, and offers web design, web development and digital marketing services.</p>
        <a href="/services" className="btn">Explore More</a>
    </div>

    <div className=" image ">
        <img  src='./soft_bg1.png'  alt=""/>
        
        <img src=''/>
    </div>

</section>
  )
}

export default Homecom