import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import * as React from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

export default function ContactForm() {

  const [dta, setdta] = React.useState({
		fullname: '',
		emailid: '',
		phone: '',
		message: '',
    location:'',
    businessname:''
	})

  const [open, setopen] = useState(false)
	const [open1, setopen1] = useState(false)
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setopen(false);
		setopen1(false);
	};

  const handlefmchnage = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setdta({ ...dta, [name]: value })
	}

	const [loading, setloading] = useState(false)



	const { fullname, emailid, phone, message } = dta

  const handlebtnclick = async ()=>{
    if (fullname !== '' && emailid !== "" && phone !== '' ) {
			if (phone.length == 12) {
				setloading(true)
				await axios.post(`https://tracking.offerborn.com/gwalaxformcreate`, dta, {
				}).then((res) => {
					if (res.data.success) {
						setopen(true);
					}
				}).catch((e) => {

						setopen1(true);
				})
				setloading(false)
				setdta({ fullname: '', emailid: '', message: '', phone: '',businessname:'',location:'' })
			} else {
				alert('Please Fill 10 Digit Mobile Number')
			}


		} else {
			alert('Please Fill All Data')
		}
  }


  return (
    <div className="  flex flex-col  grow pt-5 pr-4 pb-3 pl-4 md:w-10/12  bg-white rounded border border-sky-600 border-dashed max-md:mt-10 m-3">
      <div className="flex flex-col pb-2.5 w-full">
        <div className="flex flex-col justify-center px-1.5 w-full min-h-[63px]">
          <label htmlFor="fullName" className="flex gap-1 items-start self-start text-sm font-medium leading-5">
            <span className="text-black text-2xl leading-10">Full Name</span>
            <span className="text-red-600">*</span>
          </label>
          <input
          value={dta.fullname} onChange={handlefmchnage} name='fullname'
            type="text"
            id="fullName"
            required
            
            className="flex w-full bg-white rounded border border-gray-300 border-solid  min-h-[35px] p-3 text-2xl"
          />
        </div>

        <div className="flex flex-col justify-center px-1.5 mt-2.5 w-full min-h-[63px]">
          <label htmlFor="email" className="flex gap-1 items-start self-start text-sm font-medium leading-5">
            <span className="text-black text-2xl leading-10">Email Id</span>
            <span className="text-red-600">*</span>
          </label>
          <input
          value={dta.emailid} onChange={handlefmchnage} name='emailid'
            type="email"
            id="email"
            required
            className="flex w-full bg-white rounded border border-gray-300 border-solid  min-h-[35px] p-3 text-2xl"
          />
        </div>

        <div className="flex flex-col justify-center px-1.5 mt-2.5 w-full min-h-[63px]">
          <label htmlFor="phone" className="flex gap-1 items-start self-start text-sm font-medium leading-5">
            <span className="text-black text-2xl leading-10">Contact Number</span>
            <span className="text-red-600">*</span>
          </label>
          {/* <input
            type="tel"
            id="phone"
            required
            pattern="[0-9]{10}"
            placeholder="+91"

            className="flex w-full bg-white rounded border border-gray-500 border-solid max-w-[330px] min-h-[40px] p-3 text-2xl"
          /> */}
          <PhoneInput
          inputStyle={{width:'100%'}}
          
                    className="number "
                    country={"in"}
                    value={dta?.phone}
                    onChange={(phone) =>
                      setdta({ ...dta, phone: phone })

                    }
                />
        </div>

        <div className="flex flex-col justify-center px-1.5 mt-2.5 w-full min-h-[63px]">
          <label htmlFor="location" className="flex gap-1 items-start self-start text-sm font-medium leading-5">
            <span className="text-black text-2xl leading-10">Your Location</span>
            <span className="text-red-600">*</span>
          </label>
          <input
          value={dta.location} onChange={handlefmchnage} name='location'
            type="text"
            id="location"
            required
            className="flex w-full bg-white rounded border border-gray-300 border-solid  min-h-[35px] p-3 text-2xl"
          />
        </div>

        <div className="flex flex-col justify-center px-1.5 mt-2.5 w-full min-h-[63px]">
          <label htmlFor="businessName" className="flex gap-1 items-start self-start text-sm font-medium leading-5">
            <span className="text-black text-2xl leading-10">Your Business Name</span>
            <span className="text-red-600">*</span>
          </label>
          <input
          value={dta.businessname} onChange={handlefmchnage} name='businessname'

            type="text"
            id="businessName"
            required
            className="flex w-full bg-white rounded border border-gray-300 border-solid  min-h-[35px]  p-3 text-2xl"
          />
        </div>

        <button onClick={handlebtnclick} type="submit" className="self-center justify-center py-4 px-20 mt-8 text-2xl font-medium  text-center text-white bg-sky-600 rounded-md min-w-60 min-h-[40px] max-md:px-5">
        {loading ? <img height={20} width={20} className="m-auto" src="https://upload.wikimedia.org/wikipedia/commons/7/7a/Ajax_loader_metal_512.gif" /> : 'Get a FREE Quote'}
        </button>
      </div>

      <Snackbar open={open} sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success" sx={{ width: '100%', fontSize: 20 }}>
					Message Sent Successfully Our team will connect you soon...
				</Alert>
			</Snackbar>

			<Snackbar open={open1} sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error" sx={{ width: '100%', fontSize: 20 }}>
					Message Not Sent Successfully some Technical Issue!
				</Alert>
			</Snackbar>
    </div>
  );
}