import React from 'react'
import Header from './Components/Header'
import MyComponent from './Components/MyComponent'

const Landingpageforchennai = () => {
  return (
    <div className="md:flex flex-col items-center pt-2.5">
      <Header />
      {/* <MainLayout/> */}
      <MyComponent city={'Chennai'}/>
     
    </div>
  )
}

export default Landingpageforchennai