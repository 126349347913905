import * as React from "react";

export default function Header() {
  return (
    <div className="flex flex-wrap gap-10 items-start p-2.5 max-w-full w-[1140px]">
      {/* <div className="flex flex-col flex-1 shrink items-start basis-10 min-w-[240px] max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets%2F39ad5d3b843547288fd1e2c2a611a66c%2F3c1692669cca4d3fa3bfed10c15802eb"
          alt="Company Logo"
          className="object-contain max-w-full aspect-[3.65] w-[149px]"
        />
      </div> */}
      <button className="px-5 py-4 text-base font-semibold leading-4 text-center text-white rounded" aria-label="Call Us">
        Call Us Now
      </button>
    </div>
  );
}