import React from 'react'

const Feuturebox = ({img,title,desc,url}) => {

  const dessss = desc? desc.slice(0,200):null
  return (
    <div className="box flex flex-col justify-center items-center">
            <img style={{height:75}} src={img} alt={img}/>
            <h3 style={{fontWeight:'bolder'}}>{title}</h3>
            <p>{dessss}{desc.length> 200?'...':null}</p>
           <a href={url} className="btn">Read more</a>
        </div>
  )
}

export default Feuturebox