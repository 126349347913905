import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Home from './Pages/Home';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Aboutus from './Pages/Aboutus';
import Mainfooter from './Components/Mainfooter';
import Services from './Pages/Services';
import Technologies from './Pages/Technologies';
import Contactus from './Pages/Contactus';
import Career from './Pages/Career';
import WebDevelopment from './Pages/WebDevelopment';
import Appdevlopment from './Pages/Appdevlopment';
import Softwaredevlopment from './Pages/Softwaredevlopment';
import Digitalmarket from './Pages/Digitalmarket';
import Uxdesign from './Pages/Uxdesign';

import Seooptimizee from './Pages/Seooptimizee';
import Projects from './Pages/Projects';
import Course from './Pages/Course';
import LandingpageforGwalior from './Pages/Landing/LandingpageforGwalior';
import Landingpagefordelhi from './Pages/Landing/Landingpagefordelhi';
import Landingpageforchennai from './Pages/Landing/Landingpageforchennai';
import Landingpageforbhopal from './Pages/Landing/Landingpageforbhopal';
import Landingpageforindore from './Pages/Landing/Landingpageforindore';


function App() {

  const handlewhstappclick = ()=>{
    const whatsappUrl = `https://api.whatsapp.com/send?phone=917580943069&text=Hello Gwalax Team`;
    window.open(whatsappUrl, '_blank').focus();
  }
  return (

    <Router>


  <img onClick={handlewhstappclick} style={{position:'fixed',bottom:10,right:10,zIndex:10,cursor:'pointer'}} width={70} src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1022px-WhatsApp.svg.png'/>


<Header/>



      <Routes>
        
        <Route path='*' element={<Home/>}/>
        <Route path='/' element={ <Home/>}/>
        <Route path='/about' element={<Aboutus/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/technologies' element={<Technologies/>} />
        <Route path='/contact' element={<Contactus/>} />
        <Route path='/career' element={<Career/>} />
        <Route path='/web-development' element={<WebDevelopment/>} />
        <Route path='/app-development' element={<Appdevlopment/>} />
        <Route path='/software-development' element={<Softwaredevlopment/>} />
        <Route path='/digital-marketing' element={<Digitalmarket/>} />
        <Route path='/ui-design' element={<Uxdesign/>} />
        <Route path='/seo-optimization' element={<Seooptimizee/>} />
        <Route path='/projects' element={<Projects/>} />
        <Route path='/course' element={<Course/>} />

        <Route path='/website-development-in-gwalior' element={<LandingpageforGwalior/>} />
        <Route path='/website-development-in-delhi' element={<Landingpagefordelhi/>} />
        <Route path='/website-development-in-chennai' element={<Landingpageforchennai/>} />
        <Route path='/website-development-in-bhopal' element={<Landingpageforbhopal/>} />
        <Route path='/website-development-in-indore' element={<Landingpageforindore/>} />
        


      </Routes>

      <Mainfooter/>


    </Router>
    
  );
}

export default App;
