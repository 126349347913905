import { useMediaQuery } from '@mui/material'
import React from 'react'
import Progressstep from './Progressstep'



const Workflow = () => {

  const matches = useMediaQuery('(max-width:600px)');
  return (

    <section style={{ borderTopRightRadius:50,borderBottomLeftRadius:50,backgroundColor: '#2362b1', margin: 0, padding: 5, minHeight:'65vh' }} className="feature" id="feature">

      <h1 className="heading font-bold" style={{ color: 'white', margin: '30px 0px' }}> Our Working Process</h1>
      <div style={{ padding: matches ? '0px' : '2px 45px' }}>
        <Progressstep match={matches} />

      </div>

      <div style={{ margin: 'auto',marginTop:45, width: matches ? '100%' : '60%' }}>
        <p style={{ fontSize: matches?'1.5rem':'1.8rem', color: 'white' }}>
          We have worked across multiple industry verticals over the years and helped businesses in diverse domains. We have served a wide range of industries like Real Estate, Finance, Government, Travel, Retail, Healthcare and many more.
        </p>
      </div>

    </section>
  )
}

export default Workflow