import React from 'react'

const Pageheader = ({name}) => {
  return (
    <section style={{margin:'30px 0px ',minHeight:'10vh'}} className="project" id="project">

    <h1 className="heading font-bold">{name}</h1>

   

    </section>
  )
}

export default Pageheader