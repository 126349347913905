import React from 'react'
import Coursecard from './Coursecard'

const Coursecardmain = ({heading}) => {

    const frontendData = {
        programname: 'Front End Development',
        skills: ['HTML','CSS','JAVASCRIPT','BOOTSTRAP','REACT'],
        skillsicon: [
            'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/640px-HTML5_logo_and_wordmark.svg.png',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/2048px-Unofficial_JavaScript_logo_2.svg.png',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1200px-Bootstrap_logo.svg.png'
        ],
        programduration:'4 Months',
        enquiry:'+917898620456',
        description:'Start learning web development from basics of HTML, CSS, Javascript.Master technologies like React and Bootstrap. Get in depth knowledge of Github and Netlify and You can create website design and development using html css javascript and reactjs '
    }
    

  

    const fullstackData = {
      programname: 'Full Stack Development',
      skills: ['HTML','CSS','JAVASCRIPT','BOOTSTRAP','REACT','NODEJS','EXPRESSJS','MONGODB'],
      skillsicon: [
          'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/640px-HTML5_logo_and_wordmark.svg.png',
          'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png',
          'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/2048px-Unofficial_JavaScript_logo_2.svg.png',
          'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png',
          'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1200px-Bootstrap_logo.svg.png',
          'https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_f0b606abb6d19089febc9faeeba5bc05/nodejs-development-services.png',
          'https://cdn.icon-icons.com/icons2/2699/PNG/512/expressjs_logo_icon_169185.png',
          'https://servicenav.coservit.com/wp-content/uploads/2022/05/18-1.jpg'
      ],
      programduration:'4 Months',
      enquiry:'+917898620456',
      description:'Start learning web development from basics of HTML, CSS, Javascript.Master latest technologies like React, Node, Express. Get hands on Github, MongoDB,'
  }

  return (
    <section style={{margin:'30px 0px'}} className="feature" id="feature">

    {heading?<h1 className="heading"> awesome features </h1>:null}

    

    <div className="box-container">

        <Coursecard data={frontendData}/>
        <Coursecard data={fullstackData}/>

        


        

    </div>

</section>
  )
}

export default Coursecardmain