import React from 'react';
import Header from './Header';

import ProgressCards from './ProgressCards';
import TechnologyLogos from './TechnologyLogos';
import ContactForm from './ContactForm';
import FeatureCard from './FeatureCard';
import FeatureBox from './FeatureBox';

function MyComponent({city}) {

    const features = [
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0c6c4b6f173377daf79cf3214227686912eb9715c51e0fb8dc724ab3258bd4c0?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c",
          text: "Award Winning Team"
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/49b943acf86c456162faf8243f7b13748c40cdaf539e88aab413caafe2ea1803?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c",
          text: "7+ Years of Expertise"
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a605664997bf579d13cb1da089ec2cdb95844a8f1ddd927d811c134dfea9e229?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c",
          text: "24/7 Support"
        },
        {
          icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a605664997bf579d13cb1da089ec2cdb95844a8f1ddd927d811c134dfea9e229?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c",
          text: "Top Quality Work"
        }
      ];
  return (
    <div className=" m-2 md:w-[90%]">
      <Header />
      
      <div className="mt-8 max-w-full">
        <div className=" md:flex gap-5 max-md:flex-col ">
          <div className="md:flex flex-col ">
            <div className="md:flex flex-col w-full max-md:mt-10 max-md:max-w-full">
              <div className="self-start px-5 py-4 text-2xl font-semibold leading-4 text-center text-white rounded bg-blue-500">
                #1 Web Design & Development Company in {city}
              </div>
              <div className="mt-6 text-8xl break-words  font-bold text-black leading-[55px] md:leading-[64px] max-md:max-w-full">
                <span>Boost Your <strong className='text-blue-800'>Sales by 10X</strong> with </span>
                <span>the Best Website Development </span>
                <span>Company in {city}</span>
              </div>
              <div className="self-start mt-5 text-5xl  leading-none text-black">
                <span className="text-black">Starting from </span>
                <span  className=" text-sky-600 font-bold">₹9,999/- + GST</span>
              </div>
              <div className="mt-3.5 text-2xl leading-6 text-neutral-700 max-md:max-w-full">
                <span className="text-neutral-700">
                  Our award-winning website design agency in Chennai crafts stunning,
                </span><br />
                <span className="text-neutral-700">
                  mobile-friendly websites proven to{" "}
                </span>
                <span className="font-bold text-neutral-700">
                  boost leads by 200%
                </span>
                <span className="text-neutral-700"> and </span>
                <span className="font-bold text-neutral-700">sales by</span><br />
                <span className="font-bold text-neutral-700">30%.</span>
                <span className="text-neutral-700"> Let's turn your online presence into a powerful growth engine.</span>
              </div>
              <div className="flex flex-wrap gap-3 gap-x-11 items-start py-2.5 pr-24 mt-5 max-md:pr-5">
              {features.map((feature, index) => (
                <FeatureBox key={index} icon={feature.icon} text={feature.text} />
              ))}
            </div>
              <div className="self-start px-5 py-5 mt-10 text-3xl font-semibold leading-5 text-center text-white bg-blue-900 rounded-xl">
                Lets Discuss On Call at +91 7898620456
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
      <ProgressCards />
      <TechnologyLogos city={city} />
      <div className="flex flex-col items-center self-stretch px-20 pt-16 pb-32 mt-2.5 w-full bg-sky-100 max-md:px-5 max-md:pb-24 max-md:max-w-full">
        <div className="flex flex-col mb-0 max-w-full w-[1021px] max-md:mb-2.5">
          <div className="mr-8 ml-8 text-5xl font-bold leading-44 text-center text-black max-md:mr-2.5 max-md:max-w-full">
            We're More Than Just a Website Design Company in {city}<br />
            We're Your Growth Partner
          </div>
          <div className="px-1.5 mt-7 text-xl leading-6 text-center text-neutral-700 max-md:max-w-full">
            Unlike other website development companies in {city}, we take a holistic approach. We understand that a website is a crucial
            <br />
            asset in your digital marketing strategy. That's why we work closely with you to understand your unique business goals and create
            <br />
            a website that attracts, engages, and converts visitors into loyal customers
          </div>
          <div className="flex flex-wrap gap-5 mx-2.5 mt-8 w-full max-md:max-w-full">
          <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-10 pl-3 bg-white rounded border border-sky-600 border-dashed max-md:pr-5 max-md:max-w-full">
              <div className="text-5xl text-center text-sky-600 whitespace-nowrap leading-[50px] min-h-[50px] max-md:text-4xl">
                
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit max-md:max-w-full">
                <div className="self-start text-2xl font-semibold leading-10 text-black">
                  <p>Dynamic UI/UX Design</p>
                </div>
                <div className="mt-1.5 text-xl leading-5 text-neutral-700 max-md:max-w-full">
                A user-friendly and visually appealing website that provides
                  <br />
                  a seamless user experience across all devices
                </div>
              </div>
            </div>
            
          </div>
          
          <div className="flex flex-wrap gap-5 mx-2.5 mt-5 w-full max-md:max-w-full">
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-10 pl-3 bg-white rounded border border-sky-600 border-dashed max-md:pr-5 max-md:max-w-full">
              <div className="text-5xl text-center text-sky-600 whitespace-nowrap leading-[50px] min-h-[50px] max-md:text-4xl">
                
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit max-md:max-w-full">
                <div className="self-start text-2xl font-semibold leading-10 text-black">
                  <p>Chatbot Integration</p>
                </div>
                <div className="mt-1.5 text-xl leading-5 text-neutral-700 max-md:max-w-full">
                  Enhance user engagement and provide instant customer
                  <br />
                  support with a user-friendly chatbot
                </div>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-10 pl-3 bg-white rounded border border-sky-600 border-dashed max-md:pr-5 max-md:max-w-full">
              <div className="text-5xl text-center text-sky-600 whitespace-nowrap leading-[50px] min-h-[50px] max-md:text-4xl">
                
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit max-md:max-w-full">
                <div className="self-start text-2xl font-semibold leading-10 text-black">
                  <p>Payment Gateway Integration</p>
                </div>
                <div className="mt-1.5 text-xl leading-5 text-neutral-700 max-md:max-w-full">
                  <p>Accept online payments securely and conveniently with</p>
                  <p>seamless integration of trusted payment gateways</p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-10 pl-3 bg-white rounded border border-sky-600 border-dashed max-md:pr-5 max-md:max-w-full">
              <div className="text-5xl text-center text-sky-600 whitespace-nowrap leading-[50px] min-h-[50px] max-md:text-4xl">
                
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit max-md:max-w-full">
                <div className="self-start text-2xl font-semibold leading-10 text-black">
                  <p>Search Engine Optimization</p>
                </div>
                <div className="mt-1.5 text-xl leading-5 text-neutral-700 max-md:max-w-full">
                  <p>We optimize your website for search engines, improving</p>
                  <p>your online visibility and organic traffic</p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-12 pl-3 bg-white rounded border border-sky-600 border-dashed max-md:pr-5 max-md:max-w-full">
              <div className="text-5xl text-center text-sky-600 whitespace-nowrap leading-[50px] min-h-[50px] max-md:text-4xl">
                
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit">
                <div className="self-start text-2xl font-semibold leading-10 text-black">
                  <p>Social Media Integration</p>
                </div>
                <div className="mt-1.5 text-base leading-5 text-neutral-700">
                  <p>
                    Connect with your audience and amplify your brand across
                  </p>
                  <p>all major social media platforms</p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-11 pl-3 bg-white rounded border border-sky-600 border-dashed max-md:pr-5">
            <div className="text-5xl text-center text-sky-600 whitespace-nowrap leading-[50px] min-h-[50px] max-md:text-4xl">
                
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit">
                <div className="self-start text-2xl font-semibold leading-10 text-black">
                  <p>Website Security</p>
                </div>
                <div className="mt-1.5 text-xl leading-5 text-neutral-700">
                  <p>We implement robust security measures to protect your</p>
                  <p>website and visitor data</p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-11 pl-3 bg-white rounded border border-sky-600 border-dashed max-md:pr-5">
            <div className="text-5xl text-center text-sky-600 whitespace-nowrap leading-[50px] min-h-[50px] max-md:text-4xl">
                
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit">
                <div className="self-start text-2xl font-semibold leading-10 text-black">
                  <p>Content Management System</p>
                </div>
                <div className="mt-1.5 text-xl leading-5 text-neutral-700">
                  <p>Easily manage your website content with a user-friendly</p>
                  <p>
                    CMS platform, allowing for updates without any technical
                  </p>
                  <p>expertise</p>
                  <p>
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-11 pl-3 bg-white rounded border border-sky-600 border-dashed max-md:pr-5">
            <div className="text-5xl text-center text-sky-600 whitespace-nowrap leading-[50px] min-h-[50px] max-md:text-4xl">
                
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit">
                <div className="self-start text-2xl font-semibold leading-10 text-black">
                  <p>Mobile-Friendly Design</p>
                </div>
                <div className="mt-1.5 text-xl leading-5 text-neutral-700">
                  <p>Your website will look and function flawlessly on desktops,</p>
                  <p>
                  tablets, and smartphones, catering to today's mobile-first
                  </p>
                  <p>world</p>
                  <p>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
         
          <div className="flex justify-center items-start self-center px-5 py-5 mt-10 text-2xl font-semibold leading-6 text-center text-white rounded-xl max-md:max-w-full" />
          <button
            className="box-border relative shrink-0 px-6 py-4 ml-5 text-center bg-sky-600 rounded appearance-none cursor-pointer text-[white]"
            openLinkInNewTab={false}
          >
            Book a FREE Consultation Call Now
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center self-stretch px-20 py-20 w-full bg-sky-600 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col max-w-full w-[1100px]">
          <div className="mr-6 ml-7 text-4xl font-bold leading-10 text-center text-white max-md:mr-2.5 max-md:max-w-full">
            <span className="text-white">Unlock Exclusive </span>
            <span className="font-black text-yellow-400">
              Bonuses for Worth ₹13,000/-
            </span>
            <span className="text-white"> to Boost Your</span>
            <br />
            <span className="text-white">Website's Impact</span>
          </div>
          <div className="self-center mt-7 text-lg leading-6 text-center text-neutral-50 max-md:max-w-full">
            As a bonus, we're offering the following with your website design
            package:
          </div>
          <div className="flex flex-wrap gap-5 mt-8 w-full max-md:max-w-full">
            <div className="flex flex-col flex-1 grow shrink-0 gap-0 py-3 pr-6 pl-20 bg-white rounded border border-sky-600 border-dashed basis-0 w-fit max-md:px-5 max-md:max-w-full">
              <div className="text-lg font-semibold leading-6 text-black">
                Free Logo Design
              </div>
              <div className="text-base leading-5 text-neutral-700 max-md:max-w-full">
                <span className="text-neutral-700">
                  Get a visually striking logo that complements your website
                </span>
                <br />
                <span className="text-neutral-700">and brand identity </span>
                <span className="font-bold text-neutral-700">
                  (worth ₹2,000/-)
                </span>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start px-3.5 py-3 bg-white rounded border border-sky-600 border-dashed">
              <div className="flex flex-col min-h-[50px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ed589e6782f94ddf72edf64b920329bbd44ef6b967ddb85cea1d8ad5ac78fd2?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c"
                  className="object-contain aspect-square w-[50px]"
                  alt="Analytics Setup"
                />
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit max-md:max-w-full">
                <div className="self-start text-lg font-semibold leading-6 text-black">
                  Free Google Analytics Setup
                </div>
                <div className="mt-1.5 text-base leading-5 text-neutral-700 max-md:max-w-full">
                  <span className="text-neutral-700">
                    Track your website traffic, user behavior & conversions with
                  </span>
                  <br />
                  <span className="text-neutral-700">
                    a free Google Analytics account setup{" "}
                  </span>
                  <span className="font-bold text-neutral-700">
                    (worth ₹1500/-)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-5 mt-5 w-full max-md:max-w-full">
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start py-3 pr-16 pl-2.5 bg-white rounded border border-sky-600 border-dashed max-md:pr-5">
              <div className="flex flex-col min-h-[50px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/170df8bf0f6f6e874ba0cab055e22cd7ddc18f6cb2fa9aac3c1598c45ca0832a?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c"
                  className="object-contain aspect-[1.02] w-[52px]"
                  alt="Social Media Graphics"
                />
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit">
                <div className="self-start text-lg font-semibold leading-6 text-black">
                  Free Social Media Graphics
                </div>
                <div className="mt-1.5 text-base leading-5 text-neutral-700">
                  <span className="text-neutral-700">
                    Create a cohesive online presence with social media
                  </span>
                  <br />
                  <span className="text-neutral-700">
                    banners and graphics{" "}
                  </span>
                  <span className="font-bold text-neutral-700">
                    (worth ₹3,000/-)
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap flex-1 flex-auto gap-4 items-start px-3.5 py-3 bg-white rounded border border-sky-600 border-dashed">
              <div className="flex flex-col min-h-[50px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/3ee3a6d7056dcb374fd153e9b3ca196aeb0f01c011101c5ce04048ff9aed19dc?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c"
                  className="object-contain aspect-square w-[50px]"
                  alt="Brand Style Guide Development"
                />
              </div>
              <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit max-md:max-w-full">
                <div className="self-start text-lg font-semibold leading-6 text-black">
                  Free Brand Style Guide Development
                </div>
                <div className="mt-1.5 text-base leading-5 text-neutral-700 max-md:max-w-full">
                  <span className="text-neutral-700">
                    Establish a consistent brand voice and visual identity with
                    a
                  </span>
                  <br />
                  <span className="text-neutral-700">
                    basic brand style guide{" "}
                  </span>
                  <span className="font-bold text-neutral-700">
                    (worth ₹2,000/-)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 w-full max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-wrap grow gap-4 items-start py-3 pr-7 pl-3 w-full bg-white rounded border border-sky-600 border-dashed max-md:pr-5 max-md:mt-5">
                  <div className="flex flex-col min-h-[50px]">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/dd020ce16dd2f92ba46d85abcb7c45c265f5b0ad8179ead68eaae4f4896af011?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c"
                      className="object-contain aspect-square w-[50px]"
                      alt="SEO Optimization"
                    />
                  </div>
                  <div className="flex flex-col grow shrink-0 mt-1.5 basis-0 w-fit max-md:max-w-full">
                    <div className="self-start text-lg font-semibold leading-6 text-black">
                      Free Basic SEO Optimization
                    </div>
                    <div className="mt-1.5 text-base leading-5 text-neutral-700 max-md:max-w-full">
                      <span className="text-neutral-700">
                        We'll optimize your website's title tags, meta
                        descriptions,
                      </span>
                      <br />
                      <span className="text-neutral-700">
                        and basic on-page elements to improve search engine
                      </span>
                      <br />
                      <span className="text-neutral-700">ranking </span>
                      <span className="font-bold text-neutral-700">
                        (worth ₹3,000/-)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow gap-0 py-3 pr-7 pl-20 w-full bg-white rounded border border-sky-600 border-dashed max-md:px-5 max-md:mt-5 max-md:max-w-full">
                  <div className="text-lg font-semibold leading-6 text-black">
                    Free Pop-up Design
                  </div>
                  <div className="text-base leading-5 text-neutral-700 max-md:max-w-full">
                    <span className="text-neutral-700">
                      Create a custom pop-up to promote special offers, collect
                    </span>
                    <br />
                    <span className="text-neutral-700">
                      email addresses, or announce important updates{" "}
                    </span>
                    <span className="font-bold text-neutral-700">(worth</span>
                    <br />
                    <span className="font-bold text-neutral-700">
                      ₹1,500/-)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-start self-center px-5 py-5 mt-10 text-2xl font-bold leading-6 text-center text-black rounded-xl max-md:max-w-full" />
        </div>
        <button
          className="box-border relative shrink-0 px-6 py-4 ml-5 text-center bg-purple-600 rounded-3xl appearance-none cursor-pointer text-[white]"
          openLinkInNewTab={false}
        >
          Book a FREE Consultation Call Now
        </button>
      </div>
      <div className="mt-24 max-w-full w-[1120px] max-md:mt-10">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[32%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto max-md:mt-10">
              <div className="text-5xl font-bold leading-15 text-black max-md:mr-2.5">
                A Smooth &<br />
                Collaborative Website
                <br />
                Design Process
              </div>
              <div className="mt-2 text-xl leading-6 text-neutral-700">
                We believe in a transparent and
                <br />
                collaborative website design process in
                <br />

{city}. Here's how we work:
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[68%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c9dafab3784c2ec05b46b26016d82a12b9d3ef1ec4fc2bda998d81049a543b9a?placeholderIfAbsent=true&apiKey=39ad5d3b843547288fd1e2c2a611a66c"
              className="object-contain grow w-full aspect-[1.78] max-md:mt-5 max-md:max-w-full"
              alt="Collaborative Website Design Process"
            />
          </div>
        </div>
      </div>
      <div className="mt-32 text-6xl font-bold leading-20  text-center text-black max-md:mt-20 max-md:max-w-full">
        Hear From Our Satisfied Clients
      </div>
      <div className="px-2.5 mt-7 max-w-full text-xl leading-10 text-center text-neutral-700 w-[1120px] max-md:max-w-full">
        Don’t just take our word for it. Our clients' testimonials reflect the
        quality and effectiveness of our services, with many businesses
        <br />
        seeing significant improvements in their online performance
      </div>
      <div className="flex overflow-hidden flex-wrap gap-3.5 pb-10 mt-5 max-w-full leading-5 w-[1064px]">
        <div className="flex overflow-hidden flex-col grow shrink px-px pt-px pb-20 bg-white rounded border border-sky-600 border-solid min-w-[240px] w-[276px]">
          <div className="flex px-4 pt-4 pb-1.5 text-sm border-solid border-b-[0.667px] border-b-slate-200">
            <div className="flex flex-col items-start py-1.5 w-[97px]">
              <div className="text-base font-medium leading-6 text-black">
                Rani Gupta
              </div>
              <div className="flex mt-1 text-yellow-400 whitespace-nowrap">
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
              </div>
              <div className="self-stretch mt-1 text-neutral-700">
                Tech Solutions
              </div>
            </div>
          </div>
          <div className="mt-2 mr-8 ml-4 text-base text-neutral-700 max-md:mx-2.5">
            <p>As a B2B company, a strong online</p>
            <p>presence is critical. Gwalax understood</p>
            <p>our needs and created a website that</p>
            <p>effectively communicates our value</p>
            <p>proposition and generates leads. We've</p>
            <p>seen a significant increase in qualified</p>
            <p>leads since launching the new website.</p>
          </div>
        </div>
        <div className="flex overflow-hidden flex-col grow shrink px-px pt-px pb-16 bg-white rounded border border-sky-600 border-solid min-w-[240px] w-[277px]">
          <div className="flex px-4 pt-4 pb-1.5 text-sm border-solid border-b-[0.667px] border-b-slate-200">
            <div className="flex flex-col items-start py-1.5 w-[94px]">
              <div className="text-base font-medium leading-6 text-black">
                Shreya Rao
              </div>
              <div className="flex mt-1 text-yellow-400 whitespace-nowrap">
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
              </div>
              <div className="self-stretch mt-1 text-neutral-700">
                Coffee Corner
              </div>
            </div>
          </div>
          <div className="mt-2 mr-5 ml-4 text-base text-neutral-700 max-md:mx-2.5">
            <p>As a local coffee shop, we needed a</p>
            <p>website that was both informative and</p>
            <p>engaging. Gwalax designed a</p>
            <p>website that captures the atmosphere of</p>
            <p>our cafe and provides all the necessary</p>
            <p>information for our customers. We've</p>
            <p>seen a positive impact on foot traffic</p>
            <p>since launching the website.</p>
          </div>
        </div>
        <div className="flex overflow-hidden flex-col grow shrink pt-px pb-10 bg-white rounded border border-sky-600 border-solid min-w-[240px] w-[276px]">
          <div className="flex px-4 pt-4 pb-1.5 text-sm border-solid border-b-[0.667px] border-b-slate-200">
            <div className="flex flex-col items-start py-1 w-[136px]">
              <div className="text-base font-medium leading-6 text-black">
                David
              </div>
              <div className="flex mt-2 text-yellow-400 whitespace-nowrap">
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
                <div className="overflow-hidden"></div>
              </div>
              <div className="self-stretch mt-1.5 text-neutral-700">
                London Threads, UK
              </div>
            </div>
          </div>
          <div className="mt-2 mr-6 ml-4 text-base text-neutral-700 max-md:mx-2.5">
            <p>We hired Gwalax to create a</p>
            <p>website for our London-based fashion</p>
            <p>brand. They impressed us with their</p>
            <p>creativity and ability to understand our</p>
            <p>target audience. The resulting website is</p>
            <p>both stylish and functional, perfectly</p>
            <p>reflecting our brand identity. We've seen</p>
            <p>a significant increase in online sales</p>
            <p>since launching the website.</p>
          </div>
        </div>
      </div>
      <div className="px-5 py-5 mt-12 text-2xl font-semibold leading-6 text-center text-white rounded-xl max-md:mt-10">
        Let's Discuss for the Project
      </div>
      <div className="mt-40 text-6xl font-bold leading-10 text-center text-black max-md:mt-10 max-md:max-w-full">
        Frequently Asked Questions
      </div>
      <div className="px-2 mt-5 max-w-full text-xl leading-10 text-center text-neutral-700 w-[1120px] max-md:max-w-full">
        Have questions about our services? Check out our FAQs section to find
        answers to common queries regarding our website design
        <br />
        and development process, pricing, and more
      </div>
      <div className="overflow-hidden flex-wrap gap-10 self-stretch py-5 pr-4 pl-4 mt-5 max-w-full text-lg leading-5 text-white bg-sky-600 rounded w-[1120px] max-md:max-w-full">
        <p>What makes Gwalax the best website design company in India?</p>
        <p>
          <br />
        </p>
        <p>
          <sub className="text-base align-sub">
            Gwalax is recognized for our high-quality website designs,
            personalized service, and innovative solutions. With over 7 years of
            experience and thousands of satisfied clients, we deliver
            exceptional results that help businesses grow.
          </sub>
        </p>
      </div>
      <div className="overflow-hidden flex-wrap gap-10 self-stretch py-5 pr-4 pl-4 mt-4 max-w-full text-lg leading-5 text-white bg-sky-600 rounded w-[1120px] max-md:max-w-full">
        <p>How long does it take to design and develop a website?</p>
        <p>
          <br />
        </p>
        <p>
          <sub className="text-base align-sub">
            The timeline for website design and development varies based on the
            complexity of the project. Typically, a standard website can take
            between 4 to 6 weeks from initial consultation to launch. We ensure
            timely delivery without compromising on quality.
          </sub>
        </p>
      </div>
      <div className="overflow-hidden flex-wrap gap-10 self-stretch py-5 pr-4 pl-4 mt-4 max-w-full text-lg leading-5 text-white bg-sky-600 rounded w-[1120px] max-md:max-w-full">
        <p>What technologies do you use for website development?</p>
        <p>
          <br />
        </p>
        <p>
          <sub className="text-base align-sub">
            We use the latest technologies such as HTML5, CSS3, JavaScript
            frameworks (like React and Angular), and content management systems
            (like WordPress) to build responsive, high-performance websites that
            meet modern standards.
          </sub>
        </p>
      </div>
      <div className="overflow-hidden flex-wrap gap-10 self-stretch py-5 pr-4 pl-4 mt-4 max-w-full text-lg leading-5 text-white bg-sky-600 rounded w-[1120px] max-md:max-w-full">
        <p>Do you offer website maintenance and support after the launch?</p>
        <p>
          <br />
        </p>
        <p>
          <sub className="text-base align-sub">
            Yes, we offer comprehensive website maintenance and support services
            to ensure your website remains up-to-date, secure, and fully
            functional. Our team is available 24/7 to assist with any issues or
            updates you may need.
          </sub>
        </p>
      </div>
      <div className="overflow-hidden flex-wrap gap-10 self-stretch py-5 pr-4 pl-4 mt-4 max-w-full text-lg leading-5 text-white bg-sky-600 rounded w-[1120px] max-md:max-w-full">
        <p>
          What additional benefits do I get with your website design services?
        </p>
        <p>
          <br />
        </p>
        <p>
          <sub className="text-base align-sub">
            Along with top-quality website design, we provide additional
            benefits such as free logo design, social media setup, basic SEO
            package, one month of free maintenance, one year of free hosting,
            and a free SSL certificate to ensure your website is secure and
            optimized for success.
          </sub>
        </p>
      </div>
      <div className="px-5 py-5 mt-5 text-2xl font-semibold leading-6 text-center text-white rounded-xl max-md:max-w-full">
        Book a FREE Consultation Call Now
      </div>
      <div className="flex flex-col gap-2 items-center self-stretch pt-20 pr-96 pb-16 pl-96 mt-16 w-full text-xs font-light tracking-wide leading-3 text-center text-white bg-black max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets%2F39ad5d3b843547288fd1e2c2a611a66c%2F3c1692669cca4d3fa3bfed10c15802eb"
          className="object-contain max-w-full aspect-[3.65] w-[134px]"
          alt="Footer Logo"
        />
        <div className="mt-2.5 max-md:max-w-full">
          This site is not part of the Facebook website or Facebook Inc.
          Additionally, this site is NOT endorsed by Facebook in any way.
          FACEBOOK is a trademark of FACEBOOK, Inc. We use
          <br />
          Google remarketing pixels/cookies on this site to re-communicate with
          people who visit our site and ensure that we are able to reach them in
          the future with relevant messages and
          <br />
          information. Google shows our ads across third party sites across the
          internet to help communicate our message and reach the right people
          who have shown interest in our information
          <br />
          in the past
        </div>
      </div>
    </div>
  );
}

export default MyComponent;
