import React from 'react'
import Contact from './Contact'

const Maincontact = ({heading,sidebar}) => {
    return (
        <section style={{ borderBottomRightRadius:0,borderBottomLeftRadius:0,borderTopRightRadius:150,borderTopLeftRadius:0,backgroundColor:'white',margin: '30px 0px', minHeight: '65vh' }} className="price" id="price">

            {heading?<h1 style={{color:'#192a56'}} className="heading font-bold">contact us</h1>:null}

            <div className="box-container">

                <Contact sidebar={sidebar}/>

            </div>
        </section>
    )
}

export default Maincontact